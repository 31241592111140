<template>
  <div class="portfolio">
    <back-arrow />

    <router-link to="/portfolio/skills" class="toolbox stick-to-floor animate__animated animate__slideInRight">
      <svg xmlns="http://www.w3.org/2000/svg" width="271" height="166.705" viewBox="0 0 271 166.705">
        <g id="Skills" transform="translate(-499 -838.295)">
          <g id="Box">
            <rect id="Rectangle_86" data-name="Rectangle 86" width="271" height="146" transform="translate(499 859)" fill="#bc741f"/>
            <rect id="Rectangle_87" data-name="Rectangle 87" width="271" height="31" transform="translate(499 885)" fill="#976121"/>
            <rect id="Rectangle_88" data-name="Rectangle 88" width="271" height="26" transform="translate(499 859)" fill="#d58a32"/>
            <rect id="Rectangle_89" data-name="Rectangle 89" width="42" height="25" rx="5" transform="translate(614 873)" fill="#f5d2aa"/>
          </g>
          <path id="Path_28" data-name="Path 28" d="M683.484,916.527h35.752l9.406-17.479a64.342,64.342,0,0,0,20.858-1.494c7.048-1.884,9.72-7.4,12.772-11.815,1.276-1.847,3.076-7.72,3.049-14.026-.021-5.035-.034-8.617-.61-10.855a7.373,7.373,0,0,0-1.586-.854l-19.148,18.173s-10.124-2.561-14.026-5.854-7.806-13.634-7.806-13.634l17.563-16.37s-1.56-1.683-5.686-2.7-16.348-.106-24.323,9.276c-3.429,4.034-5.235,6.7-5.771,18.222-1.072,9.915,3.464,19.139,3.464,19.139Z" transform="translate(-8 -1)" fill="#b7b7b7"/>
          <text id="Skills-2" data-name="Skills" transform="translate(606 974)" fill="#eee" font-size="30" font-family="CaveatBrush-Regular, Caveat Brush"><tspan x="0" y="0">Skills</tspan></text>
        </g>
      </svg>
    </router-link>

    <router-link to="/portfolio/projects" class="projectboard stick-to-floor animate__animated animate__slideInRight">
      <svg xmlns="http://www.w3.org/2000/svg" width="600" height="512" viewBox="0 0 600 512">
        <g id="Board" transform="translate(-180 -493)">
          <g id="Board-2" data-name="Board">
            <rect id="Signpost" width="39" height="512" transform="translate(210 493)" fill="#714d28"/>
            <rect id="Signpost-2" data-name="Signpost" width="39" height="512" transform="translate(711 493)" fill="#714d28"/>
            <rect id="Signpost-3" data-name="Signpost" width="600" height="314" transform="translate(180 520)" fill="#714d28"/>
          </g>
          <rect id="Rectangle_80" data-name="Rectangle 80" width="184" height="107" transform="translate(248 583)" fill="#f5d2aa"/>
          <rect id="Rectangle_81" data-name="Rectangle 81" width="184" height="107" transform="matrix(0.985, -0.174, 0.174, 0.985, 389.107, 690.788)" fill="#f5d2aa"/>
          <rect id="Rectangle_82" data-name="Rectangle 82" width="129" height="75" transform="matrix(0.974, 0.225, -0.225, 0.974, 565.194, 585.789)" fill="#f5d2aa"/>
          <text id="Projects" transform="translate(433 559)" fill="#eee" font-size="30" font-family="CaveatBrush-Regular, Caveat Brush"><tspan x="0" y="0">Projects</tspan></text>
        </g>
      </svg>
    </router-link>


  </div>
</template>

<script>
//import {database} from '../firebase'
//import {tumblrClient} from '../tumblr'
import BackArrow from '../components/BackArrow.vue'

export default {
  name: 'Portfolio',
  components: {BackArrow},
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
  },
}
</script>


<style lang="scss">
  @import '@/scss/_variables.scss';

  .portfolio {
    display: flex;

    .wrapper {
      background: rgba(255, 255, 255, 0.75);
      padding: 15px;
    }

    .toolbox {
      margin-bottom: -10px;
      right: scale_width(1000);
      height: scale_height(200);

      svg {
        height: 100%;
        width: fit-content;
      }
    }

    .projectboard {
      margin-bottom: -10px;
      right: scale_width(200);
      height: scale_height(500);

      svg {
        height: 100%;
        width: fit-content;
      }
    }
  }
</style>
